<template>
  <div class="container">
    <div class="headerTop"></div>
    <div class="list">
      <div class="tips">亲爱的新同事：</div>
      <div class="tips">
        欢迎加入我们的大家庭，以下信息和大家息息相关，请详细了解哦
      </div>
      <div v-for="(item, index) in quesList" :key="index" class="_list">
        <div class="title">
          <div class="index">{{ index + 1 }}</div>
          <div class="bg">{{ item.title }}</div>
        </div>
        <div
          style="margin: 10px 0 10px 55px; white-space: pre-wrap"
          v-html="item.content"
          class="bg"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetGuideList } from "@/api/interview";

export default {
  name: "EntryTest",
  data() {
    return {
      quesList: [],
      height: 0, // 用于存储计算的 list 容器高度
    };
  },
  created() {
    this.GetGuideList();
    console.log(window.innerHeight);
    this.height = window.innerHeight - 250; // 计算 headerTop 的高度
  },
  methods: {
    async GetGuideList() {
      let res = await GetGuideList({
        openId: this.$store.state.info.openId,
      });
      if (res.errcode == 200) {
        this.quesList = res.data;
        console.log(this.quesList, "this.quesList");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100vh;
  background-image: url("../../static/img/rzzn.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* 保证背景图铺满整个视口 */

  .headerTop {
    height: 250px;
  }

  .list {
    width: 90%;
    max-height: calc(
      100vh - 250px
    ); /* 确保 list 容器的高度自适应屏幕剩余空间 */
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9); /* 设置白色背景并带有透明度 */
    text-align: left;
    border-radius: 20px;
    overflow-y: auto; /* 启用纵向滚动 */

    .tips {
      color: #3c71d9;
      font-size: 15px;
      margin: 10px 0;
      padding: 0 10px;
    }

    .bg {
      width: 250px;
      background-color: #eff5ff;
      color: #3f73da;
      margin-left: 10px;
      font-size: 15px;
      padding: 10px;
      border-radius: 10px;
    }

    .title {
      font-size: 15px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      .index {
        background-color: #c4dafe;
        color: #003993;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
      }
    }
  }
}
</style>
